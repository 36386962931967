import { Input, InputGroup, InputGroupText } from "reactstrap";

// value
// fieldKey
// name
// type
// creatorVisible
// hideCondition
// hideConditionCompareValues
// disableCondition
// disableConditionCompareValues
// fieldInputProps
export const CampaignShortCodeInput = ({
  value,
  fieldKey,
  name,
  id,
  ...props
}) => {
  return (
    <InputGroup>
      {/* TODO: @segmentation replace department and brand part of short code */}
      <InputGroupText className="pe-1">D-XXX-</InputGroupText>
      <Input value={value} id={id} name={name} {...props} />
    </InputGroup>
    // TODO: @segmentation custom renderer to display other parts of short ID, and display a warning if deparment or brand code is missing
  );
};
